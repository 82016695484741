// import User from "../../services/user";
// import { hash } from "../../services/utils";
// import { language } from "@local/theme";
import type { SmartMode } from "../../types/gateway";
import type { ClusterId } from "../../types/cluster";
import type { RuleName } from "../../types/rule";
import type { RocIdDeviceCategory, RocIdDeviceType, QuickViewCategory } from "../../types/roc-table";
import type { ChannelInfoCategoryItem, ChannelInfoPairingDevice } from "../../types/message";
import type { RulesTab, HistoryTab } from "../../types/misc";
import type {
	BaseActionData, BaseCustomParam,
	SendType, SendEventName, ZwaveMode, HbDataLayerData,
} from "../../types/analytics";

class WebtrekkActions {

	static #_getBaseActionData(): BaseActionData {
		return {
			contentId: window.location.href.substring(window.location.protocol.length + 2).replaceAll(".", "_").replace("/#/", ".").replaceAll("/", "."), // window.hbDataLayer["app_platform"]
			customerId: window.hbDataLayer!.customerId!, // User.userInfo.extension_customerId
			email: window.hbDataLayer!.email!, // (await hash(User.userInfo.email)).toUpperCase()
			pageType: window.hbDataLayer!.pageType!,
		} as const;
	}

	static #_getBaseCustomParam(): BaseCustomParam {
		return {
			1: window.hbDataLayer!.language!, // language
			3: window.hbDataLayer!.tld!, // window.location.hostname.split(".").pop()
			24: window.hbDataLayer!.app_platform!,
		} as const;
	}

	static #_sendPageUpdate(withoutPlugins: boolean): void {
		WebtrekkActions.#_send("pageupdate", withoutPlugins);
	}

	static #_sendError(errorMessage: string): void {
		const data = {
			...WebtrekkActions.#_getBaseActionData(),
			errorMessages: errorMessage,
		} as const;

		WebtrekkActions.#_send("page", data);
	}

	static #_sendAction(contentGroup: object, customParameter: object): void {
		const data = {
			...WebtrekkActions.#_getBaseActionData(),
			contentGroup: contentGroup,
			customParameter: { ...WebtrekkActions.#_getBaseCustomParam(), ...customParameter },
		} as const;

		WebtrekkActions.#_send("page", data);
	}

	static #_sendEvent(eventName: SendEventName, customClickParameter?: object | undefined, customEcommerceParameter?: object | undefined): void {
		const data = {
			linkId: eventName,
			customClickParameter: customClickParameter,
			customEcommerceParameter: customEcommerceParameter,
		} as const;

		WebtrekkActions.#_send("click", data);
	}

	static #_send(type: SendType, data: object | boolean): void {
		window.wts!.push(["send", type, data]);
	}

	public static updateHbDataLayer(hbDataLayerData: HbDataLayerData, withoutPlugins: boolean = false): void {
		for (const key in hbDataLayerData) {
			if (hbDataLayerData.hasOwnProperty(key)) {
				window.hbDataLayer![key] = hbDataLayerData[key];
			}
		}

		this.#_sendPageUpdate(withoutPlugins);
	}

	/**
	 * 3.2.2.1 Status
	 * @deprecated Old quickview doesn't exist any more
	 */
	public static acGatewaySituation(gatewaySituation: SmartMode, deviceGroupsCount: number, deviceGroupNames: Array<QuickViewCategory>, devicesPerGroup: Array<number>): void {
		const contentGroup = {
			1: "status",
		} as const;
		const customParameter = {
			25: gatewaySituation,
			26: `${deviceGroupsCount}`,
			27: deviceGroupNames.join(";"),
			28: devicesPerGroup.join(";"),
		} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.1.1 Event: Gateway Situation ändern
	 */
	public static evGatewaySituation(gatewaySituation: SmartMode): void {
		const customClickParameter = {
			1: gatewaySituation,
		} as const;

		WebtrekkActions.#_sendEvent("change_gateway_situation", customClickParameter);
	}

	/**
	 * 3.2.2.2 Geräte
	 */
	public static acDevices(deviceCategoriesCount: number, deviceCategoryNames: Array<RocIdDeviceCategory>, devicesPerCategory: Array<number>): void {
		const contentGroup = {
			1: "devices",
		} as const;
		const customParameter = {
			29: `${deviceCategoriesCount}`,
			30: deviceCategoryNames.join(";"),
			31: devicesPerCategory.join(";"),
		} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.2.1 Geräte Details
	 */
	public static acDeviceDetails(deviceName: RocIdDeviceType): void {
		const contentGroup = {
			1: "devices",
			2: "device_details",
		} as const;
		const customParameter = {
			32: deviceName,
		} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.2.2 Geräte Messwerte
	 */
	public static acDeviceMeasuredValues(clusterId: ClusterId, deviceName: RocIdDeviceType): void {
		// NOTE: sync clusterIds with "device-graph"
		const clusterTypeNames = {
			"000C": "dew point",
			"0400": "illuminance",
			"0402": "temperature",
			"0403": "pressure",
			"0405": "humidity",
			"0702": "power_consumption",
			"FD01": "ph",
			"FD02": "oydo_reduction_potential",
			"FD03": "salt_concentration",
			"FD04": "total_dissolved_solids",
			"FF69": "temperature",
			"FF81": "carbon_monoxide",
			"FF84": "dt_lawn_mower",
			"FF94": "carbon_dioxide",
			"FF95": "volatile_organic_compound",
			"FF9F": "pm2_5",
		} as const satisfies Partial<Record<ClusterId, string>>;

		const contentGroup = {
			1: "devices",
			2: "device_details",
			3: clusterTypeNames[clusterId] ?? clusterId,
		} as const;
		const customParameter = {
			32: deviceName,
		} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.2.3 Event: Aktualisieren
	 * TODO: unused
	 */
	public static evDeviceUpdate(deviceName: string): void { // TODO: check type
		const customClickParameter = {
			1: deviceName,
		} as const;

		WebtrekkActions.#_sendEvent("update", customClickParameter);
	}

	/**
	 * 3.2.2.3 Regeln
	 */
	public static acRules(rulesCount: number): void {
		const contentGroup = {
			1: "rules",
		} as const;
		const customParameter = {
			33: `${rulesCount}`,
		} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.3.1 Event: Regeln filtern
	 */
	public static evRulesTab(rulesTab: RulesTab): void {
		const customClickParameter = {
			1: rulesTab,
		} as const;

		WebtrekkActions.#_sendEvent("filter_rules", customClickParameter);
	}

	/**
	 * 3.2.2.3.2 Regel Details (Vorlagen)
	 */
	public static acRuleDetailsTemplate(ruleName: RuleName): void {
		const contentGroup = {
			1: "rules",
			2: "rule_details",
		} as const;
		const customParameter = {
			34: ruleName,
		} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.3.3 Regel Details (Zeitschaltplan)
	 */
	public static acRuleDetailsTimeSwitchingDiagram(deviceName: RocIdDeviceType, ruleName: RuleName): void {
		const contentGroup = {
			1: "rules",
			2: "rule_details",
		} as const;
		const customParameter = {
			32: deviceName,
			34: ruleName,
		} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.3.4 Event: Gateway Situation ändern
	 */
	public static evGatewaySituationChange(gatewaySituations: Array<SmartMode>): void {
		const customClickParameter = {
			1: gatewaySituations.join(";"),
		} as const;

		WebtrekkActions.#_sendEvent("select_gateway_situation", customClickParameter);
	}

	/**
	 * 3.2.2.4 Historie
	 */
	public static acHistory(): void {
		const contentGroup = {
			1: "history",
		} as const;
		const customParameter = {};

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.4.1 Event: Historie eingrenzen
	 */
	public static evHistoryTab(historyTab: HistoryTab): void {
		const customClickParameter = {
			1: historyTab,
		} as const;

		WebtrekkActions.#_sendEvent("select_history", customClickParameter);
	}

	/**
	 * 3.2.2.4.2 Event: Historie Filtern
	 */
	public static evHistoryFilter(historyFilterCount: number): void {
		const customClickParameter = {
			1: `${historyFilterCount} devices_selected`,
		} as const;

		WebtrekkActions.#_sendEvent("filter_history_by_device", customClickParameter);
	}

	/**
	 * 3.2.2.5.1 Account
	 */
	public static acAccount(): void {
		const contentGroup = {
			1: "settings",
			2: "account",
		} as const;
		const customParameter = {} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.5.2 Event: Account löschen
	 */
	public static evAccountDelete(): void {
		WebtrekkActions.#_sendEvent("delete_account");
	}

	/**
	 * 3.2.2.5.4 Event: Z-Wave aktivieren
	 */
	public static evZWaveMode(zwaveMode: ZwaveMode): void {
		const customClickParameter = {
			1: zwaveMode,
		} as const;

		WebtrekkActions.#_sendEvent("z-wave", customClickParameter);
	}

	/**
	 * 3.2.2.6 Support
	 */
	public static acSupport(): void {
		const contentGroup = {
			1: "support",
		} as const;
		const customParameter = {} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.7 About
	 */
	public static acAbout(): void {
		const contentGroup = {
			1: "about",
		} as const;
		const customParameter = {} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.7.1 AGB
	 */
	public static acAboutUserTerms(): void {
		const contentGroup = {
			1: "about",
			2: "user_terms",
		} as const;
		const customParameter = {} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.7.2 Impressum
	 */
	public static acAboutImprint(): void {
		const contentGroup = {
			1: "about",
			2: "imprint",
		} as const;
		const customParameter = {} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.8.1 Gateway
	 */
	public static acAddGateway(): void {
		const contentGroup = {
			1: "add",
			2: "gateway",
		} as const;
		const customParameter = {} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.8.2 Event: Gateway hinzufügen
	 */
	public static evAddGateway(): void {
		const customClickParameter = {
			1: "gateway",
		} as const;
		const customEcommerceParameter = {
			50: "add_gateway",
		} as const;

		WebtrekkActions.#_sendEvent("add", customClickParameter, customEcommerceParameter);
	}

	/**
	 * 3.2.2.8.3 Gerät
	 */
	public static acAddDevice(): void {
		const contentGroup = {
			1: "add",
			2: "device",
		} as const;
		const customParameter = {} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.8.4 Event: Gerät hinzufügen
	 */
	public static evAddDevice(deviceCategoryName: NonNullable<ChannelInfoCategoryItem["name"]>, deviceName: NonNullable<ChannelInfoPairingDevice["name"]>): void {
		const customClickParameter = {
			1: "device",
			2: deviceCategoryName,
			3: deviceName,
		} as const;
		const customEcommerceParameter = {
			51: "add_device",
		} as const;

		WebtrekkActions.#_sendEvent("add", customClickParameter, customEcommerceParameter);
	}

	/**
	 * 3.2.2.8.5 Gruppe
	 */
	public static acAddGroup(): void {
		const contentGroup = {
			1: "add",
			2: "group",
		} as const;
		const customParameter = {} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.8.6 Event: Gruppe erstellen
	 */
	public static evAddGroup(devicesCount: number): void {
		const customClickParameter = {
			1: "group",
			4: `${devicesCount}`,
		} as const;
		const customEcommerceParameter = {
			52: "add_group",
		} as const;

		WebtrekkActions.#_sendEvent("add", customClickParameter, customEcommerceParameter);
	}

	/**
	 * 3.2.2.8.7 Regel
	 */
	public static acAddRule(): void {
		const contentGroup = {
			1: "add",
			2: "rule",
		} as const;
		const customParameter = {} as const;

		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.8.8 Event: Regel hinzufügen
	 */
	public static evAddRule(gatewaySituations: Array<SmartMode>, ruleName: RuleName): void {
		const customClickParameter = {
			1: "rule",
			5: gatewaySituations.join(";"),
			6: ruleName,
		} as const;
		const customEcommerceParameter = {
			53: "add_rule",
		} as const;

		WebtrekkActions.#_sendEvent("add", customClickParameter, customEcommerceParameter);
	}

	public static acExplorePage(): void {
		const contentGroup = {
			1: "explore",
		} as const;
		const customParameter = {} as const;
		WebtrekkActions.#_sendAction(contentGroup, customParameter);
	}

	/**
	 * 3.2.2.8.9 Fehlermeldung
	 */
	public static acError(errorMessage: string): void {
		WebtrekkActions.#_sendError(errorMessage);
	}

	/**
	 * 3.2 Event: Explore Carousel Item View
	 */
	public static evExploreCarouselView(text: string, position: number): void {
		const customClickParameter = {
			1: text,
			9: `${position}`,
		} as const;

		WebtrekkActions.#_sendEvent("karussel_view", customClickParameter);
	}

	/**
	 * 3.2 Event: Explore Carousel Item Click
	 */
	public static evExploreCarouselClick(text: string, position: number): void {
		const customClickParameter = {
			1: text,
			9: `${position}`,
		} as const;

		WebtrekkActions.#_sendEvent("karussel_click", customClickParameter);
	}

	/**
	 * 3.3 Event: Brands interaction
	 */
	public static evExploreBrandsInteraction(text: string, interactionType: string, position: number): void {
		const customClickParameter = {
			7: text,
			8: interactionType,
			9: `${position}`,
		} as const;

		WebtrekkActions.#_sendEvent("webtrekk_ignore", customClickParameter);
	}

	/**
	 * 3.4 Event: Explore Click
	 */
	public static evExploreClick(text: string): void {
		const customClickParameter = {
			1: text,
		} as const;

		WebtrekkActions.#_sendEvent("click", customClickParameter);
	}

	/**
	 * 3.4 Event: Explore Country Change
	 */
	public static evExploreChangeCountry(countryName: string): void {
		const customClickParameter = {
			1: countryName,
		} as const;

		WebtrekkActions.#_sendEvent("change_country", customClickParameter);
	}

}

export default WebtrekkActions;
