import default_ from "./default.json";
import advancedRuleTexts from "./advancedRuleTexts.json";
import latestNewsPages from "./latestNewsPages.json";
import preLoginPages from "./preLoginPages.json";
import services from "./services.json";
import templateRuleTexts from "./templateRuleTexts.json";

export default {
	...default_,
	advancedRuleTexts,
	latestNewsPages,
	preLoginPages,
	services,
	templateRuleTexts,
} as const;
