import { mergeDeep } from "../../services/utils";
// core
import cs_default from "../core/l10n/cs/cs";
import de_default from "../core/l10n/de/de";
import en_default from "../core/l10n/en/en";
import fr_default from "../core/l10n/fr/fr";
import nl_default from "../core/l10n/nl/nl";
import ro_default from "../core/l10n/ro/ro";
import sk_default from "../core/l10n/sk/sk";
import sv_default from "../core/l10n/sv/sv";
// custom
import custom_cs_default from "./l10n/cs/cs";
import custom_de_default from "./l10n/de/de";
import custom_en_default from "./l10n/en/en";
import custom_fr_default from "./l10n/fr/fr";
import custom_nl_default from "./l10n/nl/nl";
import custom_ro_default from "./l10n/ro/ro";
import custom_sk_default from "./l10n/sk/sk";
import custom_sv_default from "./l10n/sv/sv";

const resources = {
	cs: {
		default: mergeDeep(cs_default, custom_cs_default),
	},
	de: {
		default: mergeDeep(de_default, custom_de_default),
	},
	en: {
		default: mergeDeep(en_default, custom_en_default),
	},
	fr: {
		default: mergeDeep(fr_default, custom_fr_default),
	},
	nl: {
		default: mergeDeep(nl_default, custom_nl_default),
	},
	ro: {
		default: mergeDeep(ro_default, custom_ro_default),
	},
	sk: {
		default: mergeDeep(sk_default, custom_sk_default),
	},
	sv: {
		default: mergeDeep(sv_default, custom_sv_default),
	},
} as const;

export default resources;
